export const getCookieValue = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith(nameEQ)) {
      return trimmedCookie.substring(nameEQ.length, trimmedCookie.length);
    }
  }
  return null;
};

// Utility function to get all cookies as an object
export function getAllCookies(): Record<string, string> {
  const cookies: Record<string, string> = {};
  const decodedCookies = decodeURIComponent(document.cookie);
  if (!decodedCookies) {
    return cookies;
  }

  const cookiesArray = decodedCookies.split('; ');
  for (const cookie of cookiesArray) {
    const [name, value] = cookie.split('=');
    cookies[name] = value;
  }

  return cookies;
}

// Utility function to set a cookie
export function setCookie(name: string, value: string, expires?: Date, path = '/'): void {
  let expiresString = '';

  if (expires) {
    expiresString = `; expires=${expires.toUTCString()}`;
  }

  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expiresString}; path=${path}`;
}

// Utility function to delete a cookie
export function deleteCookie(name: string, path = '/'): void {
  setCookie(name, '', new Date(0), path);
}
